var render = function render(){
  var _vm$partsList, _vm$partsList2;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.dataUploaded === true ? _c('div', {
    staticClass: "page-dashboard"
  }, [_c('v-row', {
    attrs: {
      "justify": "space-between",
      "align": "center"
    }
  }, [_c('span', {
    staticClass: "text-wrap display-1 ma-3"
  }, [_vm._v(_vm._s(_vm.$t('Dashboard')))]), _c('v-col', {
    attrs: {
      "align": "end"
    }
  })], 1), _c('v-divider', {
    staticClass: "mt-2 mb-1"
  }), _c('v-container', {
    staticClass: "px-0",
    attrs: {
      "fluid": ""
    }
  }, [_c('v-row', [_c('v-col', {
    staticClass: "mt-1"
  }, [_c('v-card', [_c('v-row', {
    staticClass: "d-block rowResume py-1",
    attrs: {
      "align": "stretch"
    }
  }, [_c('v-col', {
    staticClass: "d-flex sectionResume justify-space-between"
  }, [_c('v-card', {
    staticClass: "clickable-card",
    attrs: {
      "width": "100%",
      "to": {
        name: 'Warehouse'
      }
    }
  }, [_c('v-card-title', {
    staticClass: "subtitle-1 font-weight-bold align-start flex-nowrap"
  }, [_c('v-img', {
    staticClass: "mr-3",
    attrs: {
      "src": require("../../assets/iconWarehouse.png"),
      "alt": "icon 3D print",
      "max-width": "30px",
      "height": "auto"
    }
  }), _c('span', [_vm._v(_vm._s(_vm.$t('Warehouse')))])], 1), _c('v-card-text', {
    staticClass: "text-right font-weight-bold"
  }, [_c('span', {
    staticClass: "display-1 orange--text text-focus-in"
  }, [_vm._v(" " + _vm._s((_vm$partsList = _vm.partsList) === null || _vm$partsList === void 0 ? void 0 : _vm$partsList.length) + " ")]), _vm._v(" " + _vm._s(_vm.$tc('part(s)', (_vm$partsList2 = _vm.partsList) === null || _vm$partsList2 === void 0 ? void 0 : _vm$partsList2.length)) + " ")])], 1)], 1), _c('v-divider', {
    staticClass: "verticalDivider grey lighten-3 my-2",
    attrs: {
      "vertical": ""
    }
  }), _c('v-divider', {
    staticClass: "horizontalDivider grey lighten-3 mx-2"
  }), _c('v-col', {
    staticClass: "d-flex sectionResume justify-space-between"
  }, [_c('v-card', {
    staticClass: "clickable-card",
    attrs: {
      "width": "100%",
      "to": {
        name: 'Orders',
        query: {
          inprogress: true
        }
      }
    }
  }, [_c('v-card-title', {
    staticClass: "subtitle-1 font-weight-bold align-start flex-nowrap"
  }, [_c('v-img', {
    staticClass: "mr-3",
    attrs: {
      "src": require("../../assets/iconDelivery.png"),
      "alt": "icon 3D print",
      "max-width": "30px",
      "height": "auto"
    }
  }), _c('span', [_vm._v(_vm._s(_vm.$t('OrdersInDelivery')))])], 1), _c('v-card-text', {
    staticClass: "text-right font-weight-bold"
  }, [_c('span', {
    staticClass: "display-1 orange--text text-focus-in"
  }, [_vm._v(" " + _vm._s(_vm.ordersInDelivery) + " ")]), _vm._v(" " + _vm._s(_vm.$tc('order(s)', _vm.ordersInDelivery)) + " ")])], 1)], 1), _c('v-divider', {
    staticClass: "verticalDivider grey lighten-3 my-2",
    attrs: {
      "vertical": ""
    }
  }), _c('v-divider', {
    staticClass: "horizontalDivider grey lighten-3 mx-2"
  }), this.partsList ? _c('v-col', {
    staticClass: "d-flex sectionResume justify-space-between"
  }, [_c('v-card', {
    staticClass: "clickable-card",
    attrs: {
      "width": "100%",
      "to": {
        name: 'Orders',
        query: {
          delivered: true
        }
      }
    }
  }, [_c('v-card-title', {
    staticClass: "subtitle-1 font-weight-bold align-start flex-nowrap"
  }, [_c('v-img', {
    staticClass: "mr-3",
    attrs: {
      "src": require("../../assets/iconDelivered.png"),
      "alt": "icon 3D print",
      "max-width": "30px",
      "height": "auto"
    }
  }), _c('span', [_vm._v(_vm._s(_vm.$t('OrdersDelivered')))])], 1), _c('v-card-text', {
    staticClass: "text-right font-weight-bold"
  }, [_c('span', {
    staticClass: "display-1 orange--text text-focus-in"
  }, [_vm._v(" " + _vm._s(_vm.ordersDelivered) + " ")]), _vm._v(" " + _vm._s(_vm.$tc('order(s)', _vm.ordersDelivered)) + " ")])], 1)], 1) : _vm._e(), _c('v-divider', {
    staticClass: "verticalDivider grey lighten-3 my-2",
    attrs: {
      "vertical": ""
    }
  }), _c('v-divider', {
    staticClass: "horizontalDivider grey lighten-3 mx-2"
  }), _c('v-col', {
    staticClass: "d-flex sectionResume justify-space-between"
  }, [_c('v-card', {
    staticClass: "clickable-card",
    attrs: {
      "width": "100%",
      "to": {
        name: 'Orders'
      }
    }
  }, [_c('v-card-title', {
    staticClass: "subtitle-1 font-weight-bold align-start flex-nowrap"
  }, [_c('v-img', {
    staticClass: "mr-3",
    attrs: {
      "src": require("../../assets/iconTimer.png"),
      "alt": "icon 3D print",
      "max-width": "30px",
      "height": "auto"
    }
  }), _c('span', {
    staticClass: "text-wrap"
  }, [_vm._v(_vm._s(_vm.$t('AverageDeliveryTime')))])], 1), _c('v-card-text', {
    staticClass: "text-right font-weight-bold"
  }, [_vm.averageDeliveryTimeReady === true ? _c('span', {
    staticClass: "display-1 orange--text text-focus-in"
  }, [_vm._v(" " + _vm._s(_vm.averageDeliveryTime) + " ")]) : _c('span', {
    staticClass: "display-1 orange--text text-focus-in mr-3"
  }, [_vm._v(" - ")]), _vm._v(" " + _vm._s(_vm.$tc('day(s)', _vm.averageDeliveryTime)) + " ")])], 1)], 1), _c('v-divider', {
    staticClass: "verticalDivider grey lighten-3 my-2",
    attrs: {
      "vertical": ""
    }
  }), _c('v-divider', {
    staticClass: "horizontalDivider grey lighten-3 mx-2"
  }), _c('v-col', {
    staticClass: "d-flex sectionResume justify-space-between"
  }, [_c('v-card', {
    staticClass: "clickable-card",
    attrs: {
      "width": "100%",
      "to": {
        name: 'Orders'
      }
    }
  }, [_c('v-card-title', {
    staticClass: "subtitle-1 font-weight-bold align-start flex-nowrap"
  }, [_c('v-img', {
    staticClass: "mr-3",
    attrs: {
      "src": require("../../assets/icon3Dprinter.png"),
      "alt": "icon 3D print",
      "max-width": "30px",
      "height": "auto"
    }
  }), _c('span', [_vm._v(_vm._s(_vm.$t('PrintedParts')))])], 1), _c('v-card-text', {
    staticClass: "text-right font-weight-bold"
  }, [_c('span', {
    staticClass: "display-1 orange--text text-focus-in"
  }, [_vm._v(" " + _vm._s(_vm.totalCountParts) + " ")]), _vm._v(" " + _vm._s(_vm.$tc('part(s)', _vm.totalCountParts)) + " ")])], 1)], 1)], 1)], 1)], 1)], 1), _c('v-row', {
    attrs: {
      "align": "stretch"
    }
  }, [_c('v-col', {
    staticClass: "actionsToDoCol",
    class: _vm.quotesReady.length > 0 && _vm.ordersAwaitingPayment.length > 0 && _vm.brandHomologationsTodoList.length > 0 ? 'pb-0' : ''
  }, [_c('v-card', {
    staticClass: "cardActionsToDo"
  }, [_c('v-card-title', [_c('v-icon', {
    staticClass: "mr-2"
  }, [_vm._v(" fas fa-calendar-day ")]), _vm._v(" " + _vm._s(_vm.$t('ActionsToDo')) + " (" + _vm._s(_vm.quotesReady.length + _vm.ordersAwaitingPayment.length + _vm.brandHomologationsTodoList.length) + ") ")], 1), _vm.loadingCard && _vm.loadingCard2 && _vm.loadingCard3 && (_vm.quotesReady.length > 0 || _vm.ordersAwaitingPayment.length > 0 || _vm.brandHomologationsTodoList.length > 0) ? _c('v-row', {
    staticClass: "d-block d-lg-flex mx-1"
  }, [_vm.quotesReady.length > 0 ? _c('v-col', {
    staticClass: "mb-5"
  }, [_c('v-card', {
    attrs: {
      "id": "pendingQuotesForUseflow"
    }
  }, [_c('v-card-title', {
    staticClass: "ml-8"
  }, [_vm._v(" " + _vm._s(_vm.$t('PendingQuotes')) + " (" + _vm._s(_vm.quotesReady.length) + ") ")]), _c('v-card-text', [_vm._l(_vm.quotesReady, function (quote, indexQuote) {
    return [quote.status === 'READY' && indexQuote < _vm.showMoreQuoteQuantity ? _c('v-row', {
      key: quote._id + indexQuote,
      staticClass: "action mb-2 list-complete-item-order-scheduled",
      attrs: {
        "no-gutters": "",
        "justify": "center",
        "align": "center"
      }
    }, [_c('v-col', {
      staticClass: "rounded-l-xl outlined align-self-stretch d-flex align-center pl-2 pl-md-6",
      staticStyle: {
        "border": "1px rgba(128, 128, 128, 0.39) solid"
      },
      attrs: {
        "cols": "9"
      }
    }, [_c('div', {
      staticClass: "pa-1"
    }, [_c('span', {
      staticClass: "mr-2 text-subtitle-1"
    }, [_vm._v(" " + _vm._s(_vm.$t('quoteNumber', {
      docNumber: quote.quoteNumber
    })) + " "), _c('span', {
      staticClass: "text-no-wrap"
    }, [_vm._v(" (" + _vm._s(quote.items.length) + " " + _vm._s(_vm.$tc('part(s)', quote.items.length)) + ") ")])])])]), _c('v-col', {
      staticClass: "align-self-stretch",
      attrs: {
        "cols": "3"
      }
    }, [_c('v-btn', {
      staticClass: "white--text align-self-stretch rounded-l-0 rounded-r-xl",
      attrs: {
        "color": "orange",
        "depressed": "",
        "large": "",
        "rounded": "",
        "block": "",
        "height": "100%",
        "to": {
          name: 'Quote',
          params: {
            brandUUID: `${_vm.$appConfig.currentBrand}`,
            quoteUUID: `${quote._id}`
          }
        },
        "ripple": false
      }
    }, [_vm._v(" " + _vm._s(_vm.$t('Go!')) + " ")])], 1)], 1) : _vm._e()];
  }), _vm.quotesReady.length > 2 ? _c('v-row', {
    attrs: {
      "justify": "center",
      "align": "center"
    }
  }, [_vm.quotesReady.length > _vm.showMoreQuoteQuantity ? _c('v-btn', {
    staticClass: "text-none white px-12",
    attrs: {
      "text": "",
      "color": "orange",
      "ripple": false
    },
    on: {
      "click": function ($event) {
        _vm.showMoreQuoteQuantity = _vm.quotesReady.length;
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('More', {
    remainingQuantity: _vm.quotesReady.length - 2
  })) + " ")]) : _vm._e(), _vm.quotesReady.length === _vm.showMoreQuoteQuantity ? _c('v-btn', {
    staticClass: "white",
    attrs: {
      "icon": "",
      "color": "orange",
      "ripple": false
    },
    on: {
      "click": function ($event) {
        _vm.showMoreQuoteQuantity = 2;
      }
    }
  }, [_c('v-icon', [_vm._v("fas fa-chevron-circle-up")])], 1) : _vm._e()], 1) : _vm._e()], 2)], 1)], 1) : _vm._e(), _vm.ordersAwaitingPayment.length > 0 ? _c('v-col', {
    staticClass: "mb-5"
  }, [_c('v-card', [_c('v-card-title', {
    staticClass: "ml-8"
  }, [_c('span', {
    domProps: {
      "innerHTML": _vm._s(_vm.$tc('OrdersAwaitingPayment', _vm.ordersAwaitingPayment.length, {
        numberOfOrders: _vm.ordersAwaitingPayment.length
      }))
    }
  })]), _c('v-card-text', [_vm._l(_vm.ordersAwaitingPayment, function (order, index) {
    return [index < _vm.showMoreOrderQuantity ? _c('v-row', {
      key: order._id,
      staticClass: "action mb-2",
      attrs: {
        "no-gutters": "",
        "justify": "center",
        "align": "center"
      }
    }, [_c('v-col', {
      staticClass: "rounded-l-xl outlined align-self-stretch d-flex align-center pl-2 pl-md-6",
      staticStyle: {
        "border": "1px rgba(128, 128, 128, 0.39) solid"
      },
      attrs: {
        "cols": "9"
      }
    }, [_c('span', {
      staticClass: "mr-2 py-1 text-subtitle-1"
    }, [_vm._v(_vm._s(order.docNumber) + " "), _c('span', {
      staticClass: "text-no-wrap"
    }, [_vm._v(" " + _vm._s(_vm.$t('totalAmount', {
      totalAmount: order.totalAmount
    })))])])]), _c('v-col', {
      staticClass: "align-self-stretch",
      attrs: {
        "cols": "3"
      }
    }, [_c('v-btn', {
      staticClass: "white--text align-self-stretch rounded-l-0 rounded-r-xl",
      attrs: {
        "color": "orange",
        "depressed": "",
        "large": "",
        "rounded": "",
        "block": "",
        "height": "100%",
        "to": {
          name: 'Order',
          params: {
            brandUUID: `${_vm.$appConfig.currentBrand}`,
            orderUUID: `${order._id}`
          }
        },
        "ripple": false
      }
    }, [_vm._v(" " + _vm._s(_vm.$t('Go!')) + " ")])], 1)], 1) : _vm._e()];
  }), _vm.ordersAwaitingPayment.length > 2 ? _c('v-row', {
    attrs: {
      "justify": "center",
      "align": "center"
    }
  }, [_vm.ordersAwaitingPayment.length > _vm.showMoreOrderQuantity ? _c('v-btn', {
    staticClass: "text-none white px-12",
    attrs: {
      "text": "",
      "color": "orange",
      "ripple": false
    },
    on: {
      "click": function ($event) {
        _vm.showMoreOrderQuantity = _vm.ordersAwaitingPayment.length;
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('More', {
    remainingQuantity: _vm.ordersAwaitingPayment.length - 2
  })) + " ")]) : _vm._e(), _vm.ordersAwaitingPayment.length === _vm.showMoreOrderQuantity ? _c('v-btn', {
    staticClass: "white",
    attrs: {
      "icon": "",
      "color": "orange",
      "ripple": false
    },
    on: {
      "click": function ($event) {
        _vm.showMoreOrderQuantity = 2;
      }
    }
  }, [_c('v-icon', [_vm._v("fas fa-chevron-circle-up")])], 1) : _vm._e()], 1) : _vm._e()], 2)], 1)], 1) : _vm._e(), _vm.brandHomologationsTodoList.length > 0 ? _c('v-col', {
    staticClass: "mb-5"
  }, [_c('v-card', [_c('v-card-title', {
    staticClass: "ml-8"
  }, [_c('span', {
    domProps: {
      "innerHTML": _vm._s(_vm.$t('brandHomologationsTodoList', {
        numberOfHomologations: _vm.brandHomologationsTodoList.length
      }))
    }
  })]), _c('v-card-text', [_vm._l(_vm.brandHomologationsTodoList, function (part, index) {
    return [index < _vm.showMoreHomologationQuantity ? _c('v-row', {
      key: part._id,
      staticClass: "action mb-2",
      attrs: {
        "no-gutters": "",
        "justify": "center",
        "align": "center"
      }
    }, [_c('v-col', {
      staticClass: "rounded-l-xl outlined align-self-stretch d-flex align-center pl-2 pl-md-6",
      staticStyle: {
        "border": "1px rgba(128, 128, 128, 0.39) solid"
      },
      attrs: {
        "cols": "9"
      }
    }, [_c('div', {
      staticClass: "pa-1 d-flex flex-wrap text-subtitle-1 align-center"
    }, [_c('span', {
      staticClass: "mr-2 d-inline-block text-no-wrap"
    }, [_vm._v(" " + _vm._s(_vm.$t('Part')) + " ")]), _c('span', {
      staticClass: "d-inline-block text-truncate",
      style: _vm.quotesReady.length > 0 || _vm.ordersAwaitingPayment.length > 0 ? 'max-width: 150px' : '',
      attrs: {
        "title": part.name
      }
    }, [_vm._v(" " + _vm._s(part.name) + " ")])])]), _c('v-col', {
      staticClass: "align-self-stretch",
      attrs: {
        "cols": "3"
      }
    }, [_c('v-btn', {
      staticClass: "white--text align-self-stretch rounded-l-0 rounded-r-xl",
      attrs: {
        "color": "orange",
        "depressed": "",
        "large": "",
        "rounded": "",
        "block": "",
        "height": "100%",
        "to": {
          name: 'Part',
          params: {
            brandUUID: `${_vm.$appConfig.currentBrand}`,
            partUUID: `${part._id}`
          }
        },
        "ripple": false
      }
    }, [_vm._v(" " + _vm._s(_vm.$t('Go!')) + " ")])], 1)], 1) : _vm._e()];
  }), _vm.brandHomologationsTodoList.length > 2 ? _c('v-row', {
    attrs: {
      "justify": "center",
      "align": "center"
    }
  }, [_vm.brandHomologationsTodoList.length > _vm.showMoreHomologationQuantity ? _c('v-btn', {
    staticClass: "text-none white px-12",
    attrs: {
      "text": "",
      "color": "orange",
      "ripple": false
    },
    on: {
      "click": function ($event) {
        _vm.showMoreHomologationQuantity = _vm.brandHomologationsTodoList.length;
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('More', {
    remainingQuantity: _vm.brandHomologationsTodoList.length - 2
  })) + " ")]) : _vm._e(), _vm.brandHomologationsTodoList.length === _vm.showMoreHomologationQuantity ? _c('v-btn', {
    staticClass: "white",
    attrs: {
      "icon": "",
      "color": "orange",
      "ripple": false
    },
    on: {
      "click": function ($event) {
        _vm.showMoreHomologationQuantity = 2;
      }
    }
  }, [_c('v-icon', [_vm._v("fas fa-chevron-circle-up")])], 1) : _vm._e()], 1) : _vm._e()], 2)], 1)], 1) : _vm._e()], 1) : !(_vm.loadingCard && _vm.loadingCard2 && _vm.loadingCard3) ? _c('div', {
    staticClass: "text-center"
  }, [_c('SpinnerBeelse', {
    attrs: {
      "multiple": "",
      "color": "orange",
      "logo": "simple",
      "display-logo": false
    }
  }), _c('p', {
    staticClass: "headline pb-8"
  }, [_vm._v(" " + _vm._s(_vm.$t('Loading')) + " ")])], 1) : _vm._e()], 1)], 1), _c('v-col', {
    staticClass: "ordersTrackingCol",
    class: _vm.quotesReady.length > 0 && _vm.ordersAwaitingPayment.length > 0 && _vm.brandHomologationsTodoList.length > 0 ? '' : 'pt-3',
    attrs: {
      "cols": _vm.quotesReady.length > 0 && _vm.ordersAwaitingPayment.length > 0 && _vm.brandHomologationsTodoList.length > 0 ? 12 : 5
    }
  }, [_c('v-card', {
    staticClass: "cardOrdersTracking"
  }, [_c('v-card-title', [_c('v-icon', {
    staticClass: "mr-2"
  }, [_vm._v(" fas fa-clipboard-list ")]), _vm._v(" " + _vm._s(_vm.$t('StatusOfCurrentOrders')) + " (" + _vm._s(_vm.currentOrders.length) + ") "), _c('v-spacer'), _c('div', {
    staticClass: "legend"
  }, [_c('v-chip', {
    staticClass: "mr-1",
    attrs: {
      "small": ""
    }
  }, [_c('v-avatar', {
    attrs: {
      "left": "",
      "color": "#0d5f4f"
    }
  }), _vm._v(" " + _vm._s(_vm.$t('Manufacturing')) + " ")], 1), _c('v-chip', {
    staticClass: "mr-1",
    attrs: {
      "small": ""
    }
  }, [_c('v-avatar', {
    attrs: {
      "left": "",
      "color": "#169f85"
    }
  }), _vm._v(" " + _vm._s(_vm.$t('Manufactured')) + " ")], 1), _c('v-chip', {
    staticClass: "mr-1",
    attrs: {
      "small": ""
    }
  }, [_c('v-avatar', {
    attrs: {
      "left": "",
      "color": "#73c5b5"
    }
  }), _vm._v(" " + _vm._s(_vm.$t('Delivery')) + " ")], 1)], 1)], 1), _c('v-card-text', [_vm._l(_vm.currentOrders, function (order, index) {
    return [index < _vm.showMoreCurrentOrderQuantity && order.step < 4 ? _c('v-card', {
      key: index,
      staticClass: "mb-1",
      attrs: {
        "outlined": ""
      }
    }, [_c('v-row', {
      attrs: {
        "align": "center",
        "justify": "center"
      }
    }, [_c('v-col', {
      attrs: {
        "cols": "auto"
      }
    }, [_c('v-btn', {
      staticClass: "text-none text-nowrap noWrapInWord rounded-r-0",
      attrs: {
        "text": "",
        "to": {
          name: 'Order',
          params: {
            brandUUID: `${_vm.$appConfig.currentBrand}`,
            orderUUID: `${order._id}`
          }
        }
      }
    }, [_vm._v(" " + _vm._s(_vm.$t('orderNumber', {
      docNumber: order.invoiceNumber
    })) + " ")])], 1), _c('v-col', {
      staticClass: "pl-0 pr-6"
    }, [_c('v-progress-linear', {
      attrs: {
        "background-color": "grey lighten-2",
        "height": "10",
        "rounded": "",
        "color": order.step === 1 ? '#0d5f4f' : order.step === 2 ? '#169f85' : '#73c5b5',
        "value": order.step * 100 / 4
      }
    })], 1)], 1)], 1) : _vm._e()];
  }), _vm.currentOrders.length > 5 ? _c('v-row', {
    attrs: {
      "justify": "center",
      "align": "center"
    }
  }, [_vm.currentOrders.length > _vm.showMoreCurrentOrderQuantity ? _c('v-btn', {
    staticClass: "text-none white px-12",
    attrs: {
      "text": "",
      "color": "orange",
      "ripple": false
    },
    on: {
      "click": function ($event) {
        _vm.showMoreCurrentOrderQuantity = _vm.currentOrders.length;
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('More', {
    remainingQuantity: _vm.currentOrders.length - 5
  })) + " ")]) : _vm._e(), _vm.currentOrders.length === _vm.showMoreCurrentOrderQuantity ? _c('v-btn', {
    staticClass: "white",
    attrs: {
      "icon": "",
      "color": "orange",
      "ripple": false
    },
    on: {
      "click": function ($event) {
        _vm.showMoreCurrentOrderQuantity = 5;
      }
    }
  }, [_c('v-icon', [_vm._v("fas fa-chevron-circle-up")])], 1) : _vm._e()], 1) : _vm._e()], 2)], 1)], 1)], 1), _c('v-row', [_c('v-col', {
    staticClass: "py-0"
  }, [_c('v-card', [_c('v-card-title', [_c('v-icon', [_vm._v("fas fa-chart-area mr-2")]), _vm._v(" " + _vm._s(_vm.$t('Statistics')) + " ")], 1), _c('v-card-text', [_c('StatisticsCard')], 1)], 1)], 1)], 1)], 1)], 1) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }