var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "module-statisticsCard d-flex flex-wrap justify-center"
  }, [_c('div', {
    staticClass: "chart1 card",
    class: this.countArray.length === 0 ? 'chart1Empty' : ''
  }, [this.countArray.length === 0 ? _c('p', {
    staticClass: "emptyText text-center"
  }, [_vm._v(" " + _vm._s(_vm.$t('StatisticsEmpty')) + " ")]) : _vm._e(), _c('canvas', {
    attrs: {
      "id": "myChart",
      "width": "300",
      "height": "320"
    }
  })]), _vm._m(0)]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "chart2 card"
  }, [_c('canvas', {
    attrs: {
      "id": "myLineChart",
      "width": "300",
      "height": "320"
    }
  })]);

}]

export { render, staticRenderFns }