<template lang="html" src="./pageDashboard.template.vue"></template>

<style lang="scss" src="./pageDashboard.scss"></style>

<script>
import UploadOrgParts from '../../components/uploadOrgParts/UploadOrgParts';
import StatisticsCard from '../../components/statisticsCard/StatisticsCard';
import { ApiErrorParser, SpinnerBeelse } from '@cloudmanufacturingtechnologies/portal-components';

const i18nData = require('./pageDashboard.i18n');

export default {
  name: 'PageDashboard',
  components: {
    UploadOrgParts,
    StatisticsCard,
    SpinnerBeelse,
  },
  i18n: {
    messages: i18nData,
    dateTimeFormats: i18nData,
    numberFormats: i18nData,
  },
  data() {
    return {
      wizardItems: [],
      warehouseProgressQuota: null,
      partsList: null,
      orders: null,
      ordersAwaitingPayment: [],
      quotes: null,
      totalCountParts: 0,
      durationValues: [],
      averageDeliveryTimeReady: false,
      averageDeliveryTime: 0,
      ordersDelivered: 0,
      ordersInDelivery: 0,
      quotesReady: [],
      currentOrders: [],
      brandHomologationsTodoList: [],
      showMoreAwaitingOrders: false,
      showMoreQuotesReady: false,
      showMoreQuoteQuantity: 2,
      showMoreOrderQuantity: 2,
      showMoreHomologationQuantity: 2,
      showMoreCurrentOrderQuantity: 5,
      dataUploaded: false,
      loadingCard: false,
      loadingCard2: false,
      loadingCard3: false,
    };
  },
  created() {
    this.getParts();
    this.getBrandOrders();
    this.getBrandQuotes();
  },
  methods: {
    getParts() {
      this.$apiInstance.getBrandParts(this.$appConfig.currentBrand).then(
        (data) => {
          if (data.length > 0) {
            this.partsList = data;
          } else {
            this.partsList = [];
          }
          this.brandHomologationsTodoList = this.partsList.filter(p => {
            return p.status === 'HOMOLOGATION' && p.homologation?.status === 'READY';
          });
        },
        (error) => {
          /**
           * ERROR GET BRAND PARTS
           */
          ApiErrorParser.parse(error);
        }
      ).finally(()=>{
        this.loadingCard3 = true;
      });
    },
    dayDiff(date1, date2) {
      date1 = new Date(date1);
      date2 = new Date(date2);
      const diff = {};
      let tmp = date2 - date1;

      tmp = Math.floor(tmp / 1000); // Nombre de secondes entre les 2 dates
      diff.sec = tmp % 60; // Extraction du nombre de secondes

      tmp = Math.floor((tmp - diff.sec) / 60); // Nombre de minutes (partie entière)
      diff.min = tmp % 60; // Extraction du nombre de minutes

      tmp = Math.floor((tmp - diff.min) / 60); // Nombre d'heures (entières)
      diff.hour = tmp % 24; // Extraction du nombre d'heures

      tmp = Math.floor((tmp - diff.hour) / 24); // Nombre de jours restants
      diff.day = tmp;

      return diff.day;
    },
    /**
     * GET BRAND ORDERS
     */
    getBrandOrders() {
      this.$apiInstance
        .getBrandOrders(this.$route.params.brandUUID)
        .then(
          (orders) => {
            this.orders = orders.sort(function(a, b) {
              return b.created - a.created;
            });
            this.currentOrders = [];
            for (let i = 0; i < this.orders.length; i++) {
              if (
                this.orders[i].status === 'NEW' &&
                this.orders[i].paymentStatus === 'WAITING'
              ) {
                this.ordersAwaitingPayment.push({
                  docNumber: this.orders[i].invoiceNumber,
                  totalAmount: this.orders[i].quote.selling.totalAmount,
                  _id: this.orders[i]._id,
                });
              } else {
                if (this.orders[i].quote.dispatch[0].status === 'SCHEDULED') {
                  this.orders[i].step = 0;
                }
                if (
                  this.orders[i].quote.dispatch[0].status === 'MANUFACTURING'
                ) {
                  this.orders[i].step = 1;
                }
                if (
                  this.orders[i].quote.dispatch[0].status === 'MANUFACTURED'
                ) {
                  this.orders[i].step = 2;
                }
                if (this.orders[i].quote.dispatch[0].status === 'DELIVERY') {
                  this.orders[i].step = 3;
                  this.ordersInDelivery += 1;
                }
                if (
                  this.orders[i].quote.dispatch[0].status === 'DELIVERED' &&
                  this.orders[i].status === 'DELIVERED'
                ) {
                  this.averageDeliveryTimeReady = true;
                  this.orders[i].step = 4;
                  const orderDuration = this.dayDiff(
                    this.orders[i].created,
                    this.orders[i].last_modified
                  );
                  this.ordersDelivered += 1;
                  this.averageDeliveryTime += parseFloat(orderDuration);
                  const items = this.orders[i].quote.items;
                  for (let j = 0; j < items.length; j++) {
                    this.totalCountParts += items[j].quantity;
                  }
                } else {
                  this.currentOrders.push(this.orders[i]);
                }
              }
            }
            this.averageDeliveryTime =
              Math.round((this.averageDeliveryTime / this.orders.length) * 10) /
              10;
          },
          (error) => {
            /**
             * ERROR GET BRAND PARTS
             */
            ApiErrorParser.parse(error);
          }
        )
        .finally(() => {
          this.loadingCard = true;
        });
    },
    /**
     * GET BRAND QUOTES
     */
    getBrandQuotes() {
      this.$apiInstance
        .getBrandQuotes(this.$route.params.brandUUID)
        .then(
          (quotes) => {
            this.quotes = quotes.sort(function(a, b) {
              return a.created - b.created;
            });
            this.quotesReady = [];
            for (let i = 0; i < this.quotes.length; i++) {
              if (this.quotes[i].status === 'READY') {
                this.quotesReady.push(this.quotes[i]);
              }
            }
          },
          (error) => {
            /**
             * ERROR GET BRAND QUOTES
             */
            ApiErrorParser.parse(error);
          }
        )
        .finally(() => {
          this.dataUploaded = true;
          this.loadingCard2 = true;
        });
    },
  },
};
</script>
