<template lang="html" src="./statisticsCard.template.vue"></template>

<style lang="scss" src="./statisticsCard.scss"></style>

<script>
import Chart from 'chart.js/auto';
import { ApiErrorParser } from '@cloudmanufacturingtechnologies/portal-components';

const i18nData = require('./statisticsCard.i18n');

export default {
  name: 'StatisticsCard',
  i18n: {
    messages: i18nData,
    dateTimeFormats: i18nData,
    numberFormats: i18nData,
  },
  data() {
    return {
      barData: {},
      optionsBarDat: {},
      orders: null,
      myChart: null,
      myLineChart: null,
      ctx: null,
      ctxLineChart: null,
      partsList: null,
      partsLabels: [],
      countArray: [],
      monthsLabels: [
        'Jan.',
        'Feb.',
        'March',
        'April',
        'May',
        'June',
        'July',
        'Aug.',
        'Sept.',
        'Oct.',
        'Nov.',
        'Dec.',
      ],
      ordersDurationLabels: [],
      durationValues: [],
      backgroundsColorArray: [],
      quantityPerMonth: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      reorganizedOverTheCurrentMonthQuantityPerMonth: null,
      totalAmountPerMonth: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      reorganizedOverTheCurrentMonthTotalAmountPerMonth: null,
    };
  },
  mounted() {
    this.importDatas();
  },
  methods: {
    createChart() {
      this.ctx = document.getElementById('myChart');
      const self = this;
      this.myChart = new Chart(this.ctx, {
        type: 'bar',
        data: {
          labels: this.partsLabels,
          datasets: [
            {
              label: `${this.$t('Ordered')}`,
              data: this.countArray,
              backgroundColor: this.backgroundsColorArray,
              borderColor: this.backgroundsColorArray,
              borderWidth: 1,
              hoverBackgroundColor: 'rgba(252, 103, 0, 1)',
              hoverBorderColor: 'rgba(252, 103, 0, 1)',
            },
          ],
        },
        options: {
          indexAxis: 'y',
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            title: {
              display: true,
              text: `${this.$tc('mostOrderedTitle', this.countArray.length, {
                parts: this.countArray.length > 9 ? this.countArray.length : '',
              })}`,
            },
          },
          scales: {
            yAxes:
              {
                indexAxis: 'y',
                barPercentage: 0.9,
                barThickness: 10,
                maxBarThickness: 8,
                minBarLength: 2,
                gridLines: {
                  offsetGridLines: true,
                },
                ticks: {
                  callback: function(value, index, values) {
                    let name = self.partsLabels[index];
                    if (name.length > 15) {
                      name = name.substring(0, 15) + '...';
                    }
                    return name;
                  },
                },
              },
            xAxes: {
              indexAxis: 'x',
              ticks: {
                beginAtZero: true,
              },
            },
          },
        },
      });
      this.ctxLineChart = document.getElementById('myLineChart');
      this.myLineChart = new Chart(this.ctxLineChart, {
        type: 'line',
        data: {
          labels: this.reorganizedOverTheCurrentMonthOnMonthsLabels,
          dataIndex: 2,
          datasets: [
            {
              label: `${this.$t('Parts')}`,
              data: this.reorganizedOverTheCurrentMonthQuantityPerMonth,
              yAxisID: 'PartAmount',
              backgroundColor: 'rgba(22,159,133, 0)',
              borderColor: 'rgba(22,159,133)',
              borderWidth: 1,
              hoverBackgroundColor: 'rgba(252, 103, 0, 1)',
              hoverBorderColor: 'rgba(252, 103, 0, 1)',
            },
            {
              label: `${this.$t('TotalAmount(euros)')}`,
              data: this.reorganizedOverTheCurrentMonthTotalAmountPerMonth,
              yAxisID: 'TotAmount',
              backgroundColor: 'rgba(255,125,35, 0)',
              borderColor: 'rgba(255,125,35, 1)',
              borderWidth: 1,
              hoverBackgroundColor: 'rgba(252, 103, 0, 1)',
              hoverBorderColor: 'rgba(252, 103, 0, 1)',
            },
          ],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            title: {
              display: true,
              text: `${this.$t('Previous12MonthsOrders')}`,
            },
          },
          scales: {
            PartAmount: {
              position: 'left',
              grid: {
                color: 'rgba(22,159,133,0.1)',
                tickColor: 'rgba(22,159,133,1)',
                drawBorder: false
              },
              ticks: {
                callback: function(value, index, values) {
                  if (Number.isInteger(value)) {
                    return value > 1 ? value + ' ' + self.$t('Parts').toLowerCase() : value + ' ' + self.$t('Part').toLowerCase();
                  } else return '';
                }
              }
            },
            TotAmount: {
              position: 'right',
              grid: {
                color: 'rgba(255,125,35, 0.1)',
                tickColor: 'rgba(255,125,35, 1)',
                drawBorder: false
              },
              ticks: {
                callback: function(value, index, values) {
                  return value + ' €';
                }
              }
            },
          },
          legend: {
            display: true,
          },
        },
      });
    },
    groupBy(tableauObjets, propriete) {
      return tableauObjets.reduce(function(acc, obj) {
        const cle = obj[propriete];
        if (!acc[cle]) {
          acc[cle] = [];
        }
        acc[cle].push(obj);
        return acc;
      }, {});
    },
    importDatas() {
      this.$apiInstance.getBrandParts(this.$appConfig.currentBrand).then(
        (data) => {
          let countArray = [];
          const countArrayTotal = [];
          this.partsList = data;
          this.$apiInstance
            .getBrandOrders(this.$route.params.brandUUID)
            .then((orders) => {
              /*
                  to know the number of orders and the total amount of orders per month
                */
              this.orders = orders.sort(function(a, b) {
                return b.created - a.created;
              });
              const dateOneYearAgo = new Date().setFullYear(new Date().getFullYear(), new Date().getMonth() - 12, 0);
              for (let i = 0; i < this.orders.length; i++) {
                // Check if the date is within the last 12 months
                if(new Date(dateOneYearAgo) < new Date(orders[i].created))
                {
                  this.totalAmountPerMonth[
                    new Date(orders[i].created).getMonth()
                  ] += Math.round(this.orders[i].quote.selling.totalAmount);
                }
              }
              /*
                  to know the number of orders per part
                */
              for (let k = 0; k < this.partsList.length; k++) {
                for (let i = 0; i < orders.length; i++) {
                  if(orders[i].status === 'CANCELED') {
                    continue;
                  }
                  const items = orders[i].quote.items;
                  for (let j = 0; j < items.length; j++) {
                    if (items[j].part && items[j].part._id === this.partsList[k]._id) {
                      if(new Date(dateOneYearAgo) < new Date(orders[i].created))
                      {
                        this.quantityPerMonth[
                          new Date(orders[i].created).getMonth()
                        ] += items[j].quantity;
                      }
                      if (!this.partsList[k].count) {
                        this.partsList[k].count = items[j].quantity;
                      } else {
                        this.partsList[k].count += items[j].quantity;
                      }
                      countArray.push({
                        name: this.partsList[k].name,
                        count: this.partsList[k].count,
                        label: this.partsList[k].name,
                      });
                    }}
                }
              }
              this.reorganizedOverTheCurrentMonthQuantityPerMonth = [];
              this.reorganizedOverTheCurrentMonthTotalAmountPerMonth = [];
              this.reorganizedOverTheCurrentMonthOnMonthsLabels = [];
              for (let index = 0; index < 12; index++) {
                this.reorganizedOverTheCurrentMonthQuantityPerMonth.push(this.quantityPerMonth[new Date(
                  new Date().getFullYear(),
                  new Date().getMonth() - (11 - index)
                ).getMonth()]);
                this.reorganizedOverTheCurrentMonthTotalAmountPerMonth.push(this.totalAmountPerMonth[new Date(
                  new Date().getFullYear(),
                  new Date().getMonth() - (11 - index)
                ).getMonth()]);
                this.reorganizedOverTheCurrentMonthOnMonthsLabels.push(this.$t(this.monthsLabels[new Date(
                  new Date().getFullYear(),
                  new Date().getMonth() - (11 - index)
                ).getMonth()]));
              }

              countArray = this.groupBy(countArray, 'name');
              for (const name in countArray) {
                for (let x = 0; x < countArray[name].length; x++) {
                  countArray[name].totalCount = 0;
                  const item = countArray[name];
                  for (let y = 0; y < item.length; y++) {
                    countArray[name].totalCount = item[y].count;
                  }
                }
                countArrayTotal.push({
                  name: name,
                  count: countArray[name].totalCount,
                });
              }
              countArrayTotal.sort(function(a, b) {
                return b.count - a.count;
              });
              if (countArrayTotal.length >= 2) {
                for (let w = 0; w < countArrayTotal.length; w++) {
                  this.countArray.push(countArrayTotal[w].count);
                  this.partsLabels.push(countArrayTotal[w].name);
                }
              }
              this.backgroundsColorArray = this.generateColor(
                '#b9e2da',
                '#169f85',
                10
              );
              this.partsLabels = this.partsLabels.slice(0, 10);
              this.countArray = this.countArray.slice(0, 10);
              this.createChart();
            });
        },
        (error) => {
          /**
           * ERROR GET BRAND PARTS
           */
          ApiErrorParser.parse(error);
        }
      );
    },
    hex(c) {
      const s = '0123456789abcdef';
      let i = parseInt(c);
      if (i === 0 || isNaN(c)) return '00';
      i = Math.round(Math.min(Math.max(0, i), 255));
      return s.charAt((i - (i % 16)) / 16) + s.charAt(i % 16);
    },

    /* Convert an RGB triplet to a hex string */
    convertToHex(rgb) {
      return this.hex(rgb[0]) + this.hex(rgb[1]) + this.hex(rgb[2]);
    },

    /* Remove '#' in color hex string */
    trim(s) {
      return s.charAt(0) === '#' ? s.substring(1, 7) : s;
    },
    /* Convert a hex string to an RGB triplet */
    convertToRGB(hex) {
      const color = [];
      color[0] = parseInt(this.trim(hex).substring(0, 2), 16);
      color[1] = parseInt(this.trim(hex).substring(2, 4), 16);
      color[2] = parseInt(this.trim(hex).substring(4, 6), 16);
      return color;
    },
    generateColor(colorStart, colorEnd, colorCount) {
      // The beginning of your gradient
      const start = this.convertToRGB(colorStart);

      // The end of your gradient
      const end = this.convertToRGB(colorEnd);

      // The number of colors to compute
      const len = colorCount;

      //Alpha blending amount
      let alpha = 0.0;

      const saida = [];

      for (let i = 0; i < len; i++) {
        const c = [];
        alpha += 1.0 / len;

        c[0] = start[0] * alpha + (1 - alpha) * end[0];
        c[1] = start[1] * alpha + (1 - alpha) * end[1];
        c[2] = start[2] * alpha + (1 - alpha) * end[2];

        saida.push(`rgba(${c[0]}, ${c[1]}, ${c[2]}, 1)`);
      }

      return saida;
    },
  },
};
</script>
